<template>
  <div>
    <v-container fluid>
      <v-toolbar-title>{{ $t("Home") }}</v-toolbar-title>
      <v-row class="mt-2">
        <v-col v-for="card in cardItems" :key="card.label" cols="3">
          <v-card :color="card.colorClass" height="100">
            <h3 class="text-center pt-5">{{ $t(card.label) }}</h3>
            <h2 class="text-center pt-3">{{ card.count }}</h2>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      system_data: {
        customerCount: 0,
        groupCount: 0,
        primaryIngredientCount: 0,
        secondaryIngredientCount: 0,
        packageCount: 0,
        recipeCount: 0,
        subscriptionCounts: {
          total: 0,
          activation: 0,
          stopped: 0,
          expired: 0,
          cancelled: 0,
        },
      },
    };
  },
  computed: {
    cardItems() {
      return [
        {
          label: "Customers",
          count: this.system_data.customerCount || 0,
          colorClass: "card-blue",
        },
        {
          label: "Groups",
          count: this.system_data.groupCount || 0,
          colorClass: "card-blue",
        },
        {
          label: "Ingredients",
          count: this.system_data.primaryIngredientCount || 0,
          colorClass: "card-blue",
        },
        {
          label: "NonIngredients",
          count: this.system_data.secondaryIngredientCount || 0,
          colorClass: "card-blue",
        },
        {
          label: "Packages",
          count: this.system_data.packageCount || 0,
          colorClass: "card-blue",
        },
        {
          label: "Recipes",
          count: this.system_data.recipeCount || 0,
          colorClass: "card-blue",
        },
        {
          label: "Subscribes",
          count: (this.system_data.subscriptionCounts && this.system_data.subscriptionCounts.total) || 0,
          colorClass: "card-blue",
        },
        {
          label: "ActiveSubscribes",
          count: (this.system_data.subscriptionCounts && this.system_data.subscriptionCounts.activation) || 0,
          colorClass: "card-blue",
        },
        {
          label: "StopSubscriptions",
          count: (this.system_data.subscriptionCounts && this.system_data.subscriptionCounts.stopped) || 0,
          colorClass: "card-blue",
        },
        {
          label: "ExpiredSubscriptions",
          count: (this.system_data.subscriptionCounts && this.system_data.subscriptionCounts.expired) || 0,
          colorClass: "card-blue",
        },
        {
          label: "EndedSubscriptions",
          count: (this.system_data.subscriptionCounts && this.system_data.subscriptionCounts.cancelled) || 0,
          colorClass: "card-blue",
        },
      ];
    },
  },
  methods: {
    initData() {
      this.$store.dispatch(`zoho/refreshAccessToken`).then((res) => {
        localStorage.setItem("Zoho-Authorization", res.access_token);
      });
      this.$store.dispatch(`home/getData`).then((res) => {
        this.system_data = res.data;
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style scoped>
.card-customers {
  background-color: rgb(32, 219, 244);;
}
.card-stop {
   background-color: rgb(240, 90, 90);
}
.card-end {
   background-color: rgb(210, 240, 90);
}
.card-gray {
   background-color: rgb(207, 207, 207);
}
.card-blue {
background: linear-gradient(135deg, rgb(184, 215, 255), rgb(120, 170, 240));
}
</style>